import React from 'react';

const Accroche = () => {
    return (
        <div className="accroche" id="accroche">
            <div className='accroche-title'>
                <h2>Pourquoi venir faire un soin énergétique ?</h2>
            </div>
            <div className="block-text-accroche">
                <h3>Comment fonctionne les soins énergétiques ?</h3>
                <p>Le corps humain est constitué d’énergies circulant de façon constante et libre. C’est cette libre circulation énergétique qui permet au corps de se maintenir en bonne santé mentale et physique. 
                Cependant, du fait de chocs émotionnels, d’accidents, de maladie, de pensée négative, ou de peur collective des blocages se créent et s’emmagasinent dans notre corps et déséquilibrent cette circulation énergétique, en introduisant des ruptures de communication entre les méridiens, ou au niveau des chakras. 
                Ce déséquilibre se manifeste alors très concrètement dans notre corps et notre esprit, puisqu’ils peuvent provoquer des troubles physiques ou psychiques avec un besoin de se libérer d’un passé qui nous pèse. Ces déséquibres sont très différents d’un individu à l’autre. 
                Il s’adresse à toutes personnes souhaitant retrouver un équilibre physique, émotionnel, mental et spirituel.
                La pratique de soins énergétiques vise à apaiser ces maux et à retrouver du bien-être, mais s’attaque également directement à leur origine, en levant le ou les blocages énergétiques emmagasinés. 
                Ces soins permettent également de se recentrer et de se ré-harmoniser avec son corps et ses énergies, et de se relaxer de façon durable et profonde. </p>
               
                <h3>Comment se déroule une séance ?</h3>
                <p>La séance durent entre 45 mn et 1h30 selon le soin et les besoins de la personne. 
                    Avant de démarrer le soin, il y a une phase d’échanges afin de déterminer les besoins de la séance. 
                    Puis, vous vous s’allongerez habillé afin de recevoir le soin. Vous serez invité à vous détendre. Pendant ce temps, je procèderai à une écoute de votre corps et réaliserai différents mouvements, avec ou sans contact physique selon la phase.  </p>
                                    
                <h3>A qui s’adressent les soins énergétiques ?</h3>
                <p>Ils s’adressent à toute personne ressentant un mal-être physique ou psychique, ou victimes de chocs émotionnels ou physiques, externes ou internes. 
                Les soins énergétiques peuvent aussi accompagner les consultants dans les phases critiques de leur vie en amélioration la communication entre les méridiens du corps,  les chakras, facilitant ces périodes de transition. 
                Enfin, les soins énergétiques peuvent également être réalisés une à deux fois par an de manière préventive à chaque changement de saison, durant lesquels je procèderai à un simple nettoyage énergétique. </p>
            </div>
        </div>
    );
};
 
export default Accroche;