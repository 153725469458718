import React , {useState} from 'react';
import { NavLink,Link } from 'react-router-dom';
import logo from '../../src/public/medias/g15.png';

const HeadBanner = () => {

    
    const [showNavigation, setShowNavigation ] = useState(true)

    const activeNav = ()=>{
        setShowNavigation(false)
    }

    const desactiveNav = ()=>{
        setShowNavigation(true)
    }

    return (
        <div className="headbanner">
            <div className="logo">
                <div className="logo-display">
                    <a href='#accueil'>
                        <img src={logo} alt="logo bio etre energetique"/>
                    </a> 
                </div>
            </div>
            {
                showNavigation ? 
                (
                    <>  
                        <div className="hamburger-menu" onClick={activeNav}>
                            <span className='slice1'></span>
                            <span className='slice2'></span>
                            <span className='slice3'></span>
                        </div>
                    </>
                )
                :
                ( 
                    <>
                        <div className="hamburger-menu" onClick={desactiveNav}>
                            <div className='slice-1'></div>
                            <div className='slice-2'></div>
                        </div>
                        <div className='nav-open' activeclassname="active" >
                            <div className='nav-open-body'>
                                <a href='#accueil' onClick={desactiveNav}> Accueil</a>
                                <a href='#accroche' onClick={desactiveNav}>Pourquoi ?</a>
                                <a href='#a-propos' onClick={desactiveNav}> A propos</a>
                                <a href='#techniques' onClick={desactiveNav}> Techniques</a>
                                <a href='#temoignages' onClick={desactiveNav}> Témoignages</a>
                                <a href='#contact' onClick={desactiveNav}> Contact</a>
                            </div>
                        </div>
                    </>
                )
            }
        
        </div>
    );
};

export default HeadBanner;