import React from 'react';
import reiki from '../../src/public/medias/reiki.jpg'
import magnetisme from '../../src/public/medias/magnetisme.jpg'
import massage from '../../src/public/medias/massage.jpg'

const Techniques = () => {
    return (
        <div className="techniques" id="techniques">
            <div className='phrase-intro-techniques'>
                <h2>Mes techniques de soins :</h2>
            </div>
            <div className="techniques-display">
                <div className="reiki-massage-magnetisme">
                    <img src={reiki} alt="technique du reiki"/>
                    <div className='description-technique'>
                        <div className="description-technique-title">
                            <h3>Reiki Usui</h3>
                        </div>
                        <div className="description-technique-text">
                            <p>Le reiki est une technique thérapeutique d’orgine japonaise basée sur la transmission d’énergie par imposition des mains. Il permet de soulager aussi bien les souffrances physiques que psychologiques. Il fortifie le corps et l’esprit, en rééquilibrant les chakras, restaure l’harmonie et le bien-être spirituel, équilibre la circulation de l’énergie dans le corps, dissout les blocages, et nettoie le corps. Le reiki permet aussi de travailler sur des situations passées, ou futures, et accompagner les défunts.</p>
                        </div>
                        <a href="https://fr.wikipedia.org/wiki/Reiki" target="_blank">Plus d'infos</a>
                    </div>
                </div>
                <div className="reiki-massage-magnetisme">
                    <img src={magnetisme} alt="technique du magnétisme"/>
                    <div className='description-technique'>
                        <div className="description-technique-title">
                            <h3>Soin énergétique</h3>
                        </div>
                        <div className="description-technique-text">
                            <p>Le soin visera à effectuer un nettoyage énergétique afin de lever les blocages, rééquilibrer, relancer la circulation énergétique et l’harmoniser. Il permettra de renforcer les processus d’auto réparation et d’auto guérison du corps. 
                            Certaines prières peuvent être utilisées, comme celles pour les verrues ou les vers. La « prière du feu » agit sur les brûlures, les zonas, la radiothérapie et la plupart des maux inflammatoires dont le nom fini par «...ite» (exemple: tendinite...). 
                            Il est possible aussi d’effacer les « mémoires karmiques » c’est à dire les événements désagréables qui ne cessent de se répeter dans votre vie.
                            </p>
                        </div>
                        <a href="https://fr.wikipedia.org/wiki/Magn%C3%A9tisme" target="_blank">Plus d'infos</a>
                    </div>
                </div>
                <div className="reiki-massage-magnetisme">
                    <img src={massage} alt="technique d'EMF Balancing"/>
                    <div className='description-technique'>
                        <div className="description-technique-title">
                            <h3>Prénatothérapie</h3>
                        </div>
                        <div className="description-technique-text">
                            <p>C’est une thérapie prénatale, un retour arrière pour un autre futur... Le massage métamorphique pourrait se résumer ainsi. Entendez « le passage de la chrysalide au papillon », une métamorphose rendue possible par une levée de blocages subconscients et tirant sa source lors de la gestation ou de l’accouchement. Douleurs chroniques, insomnies, comportements pathologiques (enfants hyperactifs) et stress peuvent s’en trouver soulagés. Sans oublier un but plus ambitieux : harmoniser tout son être « afin qu’il devienne qui il est ».</p>
                        </div>
                        <a href="https://fr.wikipedia.org/wiki/M%C3%A9tamorphisme_(homonymie)" target="_blank">Plus d'infos</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Techniques;