import React from 'react';
import soin from '../../src/public/medias/soins.jpeg'

const Presentation = () => {
    return (
        <div className="presentation" id="a-propos">
            <h2>Qui suis-je ? </h2>
            <div className='content-presentation'>
                <div className="presentation-right">
                    <img src={soin} alt="soins par Géraldine Thelliez"/>
                </div>
                <div className="presentation-left">
                    <div className="block-text">
                        <h3>Mon histoire :</h3>
                         
                        <p>Energeticienne et femme passionnée par la nature, l’humanité et son ambivalence. J’ai cherché à comprendre le mystère humain pour aider les personnes à se liberer, s’épanouir pour devenir elle-même et suivre leur chemin. </p>
                        <p>Durant plusieurs années, je me suis alors tournée vers les relations d’aides à travers les thérapies brèves après avoir passé un Master en PNL (Programmation Neuro-Linguistique).</p>
                        <p>Fascinée par les thérapies corporelles non verbales et holitiste, je me suis découverte fortuitement des capacités de magnétiseuse. Après plusieurs formations en énergétique (EFT, Matrix Remprinting, prénatothérapie, Reiki Usui, soins énergetiques et Esséniens), et quelques années d’accompagnement j’ai pu unifier mes pratiques (verbales et corporelles) afin de proposer une approche psycho-énergétique.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Presentation;