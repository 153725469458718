import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-interactions'>
                <div className='link-display'>
                    <a href="#">Mentions légales</a>
                    <a href="#contact">Contacts</a>
                </div>
                <a className="linkgoup"href="#accueil">
                    <div className='go-up' >
                        <i className="fas fa-arrow-up"></i>
                        <p>Revenir en haut</p>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default Footer;