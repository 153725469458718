import React from 'react';
import Accroche from '../components/Accroche';
import CalltoAction from '../components/CalltoAction';
import Footer from '../components/Footer';
import HeadBanner from '../components/HeadBanner';
import Hero from '../components/Hero';
import Presentation from '../components/Presentation';
import Techniques from '../components/Techniques';
import Temoignages from '../components/Temoignages';

const Home = () => {
    return (
        <div>
            <HeadBanner/>
            <Hero/>
            <Accroche/>
            <Presentation/>
            <Techniques/>
            <Temoignages/>
            <CalltoAction/>
            <Footer/>
        </div>
    );
};

export default Home;