import React from 'react';

const Temoignages = () => {
    return (
        <div className="temoignages" id="temoignages">
               <div className='phrase-intro-temoignages'>
                <h2>Témoignages :</h2>
            </div>
            <div className="temoignages-display">
                <div className="temoin-text">
                    <p>"Ces soins énergétiques  sont parfait pour repartir sur de bonnes bases, et nettoyer les différents points du passé... pour construire un nouveau avenir .
                    Nous avons tous besoin de rechargées nos batterie de temps en temps.
                    Mme Thelliez à su me faire un bon nettoyage de mes énergies lourde à porter.
                    Je vous recommande ces soins."</p>
                    <h4>William</h4>
                </div>
                <div className="temoin-text">
                    <p>"Je vais consulter Mme Thelliez chaque fois que j'ai besoin d'un nettoyage énergétique et chaque fois que j'ai besoin d'un accompagnement sur des émotions particulières. Je la trouve très accueillante et complètement à l'écoute. Je recommande toujours ses soins à mes proches et amis.
                        J'ai toute confiance dans le choix des possibles qu'elle me propose."</p>
                    <h4>Odile</h4>
                </div>
                <div className="temoin-text">
                    <p>"Un grand merci à Géraldine qui s'occupe de mon garçon de 12 ans.
                    Des résultats dès la première séance.
                    Mon fils retrouve le sourire à chaque séance et à hâte de la revoir.
                    Une personne sensible et très douce.
                    Je la recommande."</p>
                    <h4>Laetitia</h4>
                </div>
            </div>
        </div>
    );
};

export default Temoignages;