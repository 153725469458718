import React from 'react';



const CalltoAction = () => {

    return (
        <div className='calltoaction' id='contact'>
            <h2>Contact</h2>
            <h4>Les séances de soins auront lieu uniquement sur rendez vous.</h4>
            <div className='horaires'>
                <p>Du lundi au samedi,</p>
                <p> de 9h00 à 18h00.</p>
            </div>
            <div className='calltoaction-content'>
                <div className="presentation-right-calltoaction">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.094180217928!2d-3.121555299029136!3d47.50755704726063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4810735d47bf1993%3A0xa7d41e3cf0c6f2d9!2zQmlvLcOqdHJlIMOpbmVyZ8OpdGlxdWU!5e0!3m2!1sfr!2sfr!4v1643033948587!5m2!1sfr!2sfr" loading="lazy"></iframe>
                </div>
                <div className="presentation-left-calltoaction">
                    <div className="block-text-calltoaction">
                        <div className='coordonnes-card'>
                            <h4>PRENDRE RENDEZ VOUS : </h4>
                            <div className='adresse'>
                                <h4>Géraldine Thelliez</h4>
                                <address> 5 bis, chemin du Men Du</address>
                                <address> 56510 Saint-Pierre Quiberon</address>
                            </div>
                            <div className='phone-mail'>
                                <p>Par téléphone : 06 64 15 22 69 </p>
                                <p>Par e-mail : bioetre.energetique@gmail.com </p>
                                <p>Ne remplace pas la médecine.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalltoAction;


