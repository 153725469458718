import React from 'react';
import zenRock from '../../src/public/medias/zen-rock.jpeg'

const Hero = () => {
    return (
        <div className="hero" id="accueil">
            <div className="top-hero">
                <div className="left">
                    <div className="infos-display">
                        <div className='content-infos-display'>
                            <div className='title-container'>
                                <h1>Bio-être énergetique</h1> 
                                <h2>Reiki Usui</h2>
                                <h2>Soin énergetique</h2>
                                <h2>Prénatothérapie</h2>
                            </div>
                        </div>
                        <div className='content-infos-display'>
                            <p className='content-infos-display-intro'>Réduisez votre stress, apaisez votre mental, libérez vos blocages.Rééquilibrez l’énergie entre votre corps et votre esprit.</p>
                            <p>Praticienne en <strong>psycho-énergétique</strong> installée depuis plus de 9 ans, je vous propose des <strong>soins énergétiques</strong>, sur rendez vous à <strong>Saint-Pierre Quiberon</strong>, ou à distance.</p> 
                            <p>Je vous accueille avec plaisir en respectant les règles de sécurité sanitaire en vigueur. Portez vous bien!</p>
                        </div>
                        <div className='action-button-contact'>
                            <a href='#contact' className='contact-button'>Prendre un rendez-vous</a>
                        </div>
                    </div>
                   
                </div>
                <div className="right">
                    <img src={zenRock} alt="pile de pierre zen"/>
                </div>
            </div>
            <div id="scroll-wrapper">
                    <div id="scroll-wrapper-inner">
                        <div id="scroll-title">Visitez le site</div>
                        <div id="scroll-down"></div>
                    </div>
                </div>
        </div>
    );
};

export default Hero;

