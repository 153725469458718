import './App.css';
import HeadBanner from './components/HeadBanner';
import Home from './pages/Home';
import './style/index.scss';
import { BrowserRouter, Routes , Route} from "react-router-dom";
import Presentation from './components/Presentation';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="" exact element={<Home/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
